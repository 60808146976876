import { useMemo, useState } from "react";

export interface ImportTask {
  url: string;
  status: "pending" | "importing" | "success" | "error";
  statusText?: string;
  error?: Error;
  id: string;
}

export function useImportTasks() {
  const [tasks, setTasks] = useState<ImportTask[]>([]);
  return useMemo(() => {
    return {
      tasks,
      updateTask: (id: string, update: Partial<ImportTask>) => {
        setTasks((tasks) =>
          tasks.map((task) => (task.id === id ? { ...task, ...update } : task))
        );
      },
      queueTasks: (urls: string[]) => {
        const newTasks = urls
          .filter((url) => !tasks.some((task) => task.url === url))
          .map((url) => ({
            url,
            status: "pending" as ImportTask["status"],
            id: Math.random().toString(),
          }));
        setTasks((tasks) => [...tasks, ...newTasks]);
      },
    };
  }, [tasks]);
}

export type UseImportTasks = ReturnType<typeof useImportTasks>;
