import { fetchApi, requireOk } from "~/services/fetchApi";
import { ImportPostBody } from "~/screens/admin/postsimport/data/importPostTypes";

export function fetchImportPost(args: ImportPostBody) {
  return fetchApi("/import/post", {
    method: "POST",
    body: JSON.stringify(args),
  })
    .then(requireOk)
    .then<ImportPostResult>((res) => res.json());
}

export interface ImportPostResult {
  ok: true;
  duplicate?: true;
}
