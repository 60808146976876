import React, { useState } from "react";
import styled from "@emotion/styled";
import { useSelectedProjectId } from "~/store/project/projectHooks";
import TextareaInput from "~/components/inputs/text/TextareaInput";
import Button from "~/components/button/Button";
import { SelectorLabelWrapper } from "~/elements/misc/SelectorLabelWrapper";
import MultiProjectSelector from "~/elements/context/project/MultiProjectSelector";
import { PostImporter } from "~/screens/admin/postsimport/importer/PostImporter";
import {
  UseImportTasks,
  useImportTasks,
} from "~/screens/admin/postsimport/importer/useImportTasks";
import { PostsImporterInfo } from "~/screens/admin/postsimport/PostsImporterInfo";

interface PostsImportViewProps {}

export function PostsImportView(props: PostsImportViewProps) {
  const [input, setInput] = useState(initialInput);
  const { tasks, queueTasks, updateTask } = useImportTasks();
  const projectId = useSelectedProjectId();
  return (
    <Container>
      <Title>Posts importieren</Title>
      <TextareaInput
        value={input}
        onChange={(event) => {
          setInput(event.target.value);
        }}
      />
      <Group>
        <SelectorLabelWrapper label={"Project"}>
          <MultiProjectSelector multi={false} />
        </SelectorLabelWrapper>
        <Button
          disabled={!projectId}
          variant={"is-primary"}
          onClick={() => {
            const urls = input.split("\n");
            queueTasks(urls);
          }}
        >
          Importieren
        </Button>
      </Group>
      {tasks.length > 0 ? (
        <PostImporter tasks={tasks} updateTask={updateTask} />
      ) : (
        <PostsImporterInfo />
      )}
    </Container>
  );
}

const Container = styled.div`
  & > * {
    margin-bottom: 1em;
  }
`;

const Group = styled.div`
  display: flex;
  gap: 1em;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const initialInput = process.env.GATSBY_IMPORT_POSTS || "";
