import styled from "@emotion/styled";
import React from "react";
import Alert from "~/components/alert/Alert";

interface PostsImporterInfoProps {}

export function PostsImporterInfo(props: PostsImporterInfoProps) {
  return (
    <Alert type={"info"}>
      Aktuell werden folgende Plattformen unterstützt:
      <List>
        <li>
          Instagram Post{" "}
          <small>
            (z. B.{" "}
            <a
              href={"https://www.instagram.com/p/DB6yT2pNucI"}
              target={"_blank"}
              rel="noreferrer"
            >
              https://www.instagram.com/p/DB6yT2pNucI
            </a>
            )
          </small>
        </li>
        <li>
          Instagram Gallery Post
          <small>
            (z. B.{" "}
            <a
              href={"https://www.instagram.com/p/DDIBQvBsKvJ/?img_index=2"}
              target={"_blank"}
              rel="noreferrer"
            >
              https://www.instagram.com/p/DDIBQvBsKvJ/?img_index=2
            </a>
            )
          </small>
        </li>
        <li>
          Instagram Story{" "}
          <small>
            (z. B.{" "}
            <a
              href={
                "https://www.instagram.com/stories/dsv_biathlon/3511464103063897150/"
              }
              target={"_blank"}
              rel="noreferrer"
            >
              https://www.instagram.com/stories/dsv_biathlon/3511464103063897150/
            </a>
            )
          </small>
        </li>
        <li>
          Twitter Post{" "}
          <small>
            (z. B.{" "}
            <a
              href={"https://x.com/Sport__h24/status/1850145518707421523"}
              target={"_blank"}
              rel="noreferrer"
            >
              https://x.com/Sport__h24/status/1850145518707421523
            </a>
            )
          </small>
        </li>
      </List>
    </Alert>
  );
}

const List = styled.ul`
  margin-left: 1em;
  list-style: disc;
`;
