import React, { useEffect, useRef } from "react";
import { SimpleTable } from "~/components/table/SimpleTable";
import {
  ImportTask,
  UseImportTasks,
} from "~/screens/admin/postsimport/importer/useImportTasks";
import { fetchImportPost } from "~/screens/admin/postsimport/data/fetchImportPost";
import { useSelectedProjectId } from "~/store/project/projectHooks";

type PostImporterProps = Pick<UseImportTasks, "tasks" | "updateTask">;

export function PostImporter({
  tasks: nextTasks,
  updateTask,
}: PostImporterProps) {
  const projectId = useSelectedProjectId();
  const $tasks = useRef<ImportTask[]>(nextTasks);
  $tasks.current = nextTasks; // or wrap in useEffect?

  useEffect(() => {
    let active: ImportTask | null = null;
    if (!projectId) return;

    async function handleNext() {
      if (active) return;
      active = $tasks.current.find((task) => task.status === "pending");
      if (!active) return;
      updateTask(active.id, { status: "importing" });
      try {
        await fetchImportPost({
          url: active.url,
          projectId,
        }).then((result) => {
          updateTask(active.id, {
            status: "success",
            statusText: result.duplicate && "Success (duplicate)",
          });
        });
      } catch (error) {
        updateTask(active.id, { status: "error", error });
      } finally {
        active = null;
        // handleNext();
      }
    }
    const timer = setInterval(handleNext, 1000);
    return () => clearInterval(timer);
  }, [projectId]);

  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>URL</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {nextTasks.map((task) => {
          return (
            <tr key={task.url}>
              <td>{task.url}</td>
              <td>{formatTaskStatus(task)}</td>
            </tr>
          );
        })}
      </tbody>
    </SimpleTable>
  );
}

function formatTaskStatus(task: ImportTask) {
  if (task.statusText) return task.statusText;
  if (task.status === "error") {
    return `Error: ${task.error?.message}`;
  }
  return task.status;
}
