import React from "react";
import { PostsImportView } from "./PostsImportView";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";

export function PostsImportScreen() {
  return (
    <ProjectSelectionContext resolveFromUrl>
      <PostsImportView />
    </ProjectSelectionContext>
  );
}
